import {Pipe, PipeTransform} from '@angular/core';
import {IVideo} from "../models/video-data.model";

@Pipe({
  name: 'videoCarouselSeparator'
})
export class VideoCarouselSeparatorPipe implements PipeTransform {
  /**
   * Separates the full playlist into groups with 4 videos in each array
   * @param value: The array of the full playlist
   */
  transform(value: IVideo[]): any {
    return value.length < 5 ? [value] : this.separateVideos(value);
  }

  private separateVideos(value: IVideo[]): Array<IVideo[]> {
    let currentIndex = 0;
    return value.reduce((displayVideosArray: Array<IVideo[]>, currentVideo: IVideo) => {
      if (!displayVideosArray[currentIndex]) {
        displayVideosArray[currentIndex] = new Array<IVideo>();
      }
      if (displayVideosArray[currentIndex].length > 3) {
        currentIndex++;
        displayVideosArray[currentIndex] = new Array<IVideo>();
      }
      displayVideosArray[currentIndex].push(currentVideo);
      return displayVideosArray;
    }, new Array<IVideo[]>());
  }
}
