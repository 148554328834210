import {Component} from '@angular/core';
import {Router} from '@angular/router';

export interface NavItemModel {
  route: string;
  url: string;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  isNavbarOpen = false;

  menuItems: Array<NavItemModel> = [{
    route: '/kezdolap',
    url: 'Kezdőlap'
  }, {
    route: '/rolam',
    url: 'Rólam'
  }, {
    route: '/szolgaltatasok',
    url: 'Szolgáltatások'
  }, {
    route: '/kapcsolat',
    url: 'Kapcsolat'
  }];

  constructor(private router: Router) {
  }

  toggleNavigationBar() {
    this.isNavbarOpen = !this.isNavbarOpen;
  }
}
