import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {KezdolapComponent} from './kezdolap/kezdolap.component';
import {RolamComponent} from './rolam/rolam.component';
import {SzolgaltatasokComponent} from './szolgaltatasok/szolgaltatasok.component';
import {KapcsolatComponent} from './kapcsolat/kapcsolat.component';


const routes: Routes = [
  {path: 'kezdolap', component: KezdolapComponent},
  {path: 'rolam', component: RolamComponent},
  {path: 'szolgaltatasok', component: SzolgaltatasokComponent},
  {path: 'kapcsolat', component: KapcsolatComponent},
  {path: '', redirectTo: '/kezdolap', pathMatch: 'full'},
  {path: '**', redirectTo: '/kezdolap'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
