import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatVideoComponent} from "mat-video/lib/video.component";
import {SlickCarouselComponent} from "ngx-slick-carousel";
import {KopterVideoWrapper} from "./video-wrapper/kopter-video-wrapper";
import {VideoFetcherService} from "../services/video-fetcher.service";
import {Observable, of} from "rxjs";
import {IVideo} from "../models/video-data.model";

@Component({
  selector: 'app-kezdolap',
  templateUrl: './kezdolap.component.html',
  styleUrls: ['./kezdolap.component.scss']
})
export class KezdolapComponent implements OnInit {
  @ViewChildren(KopterVideoWrapper) kopterVideoElements!: QueryList<KopterVideoWrapper>;
  @ViewChild('slick', {static: true}) slick: SlickCarouselComponent;
  @ViewChild('matVideo', {static: true}) matVideo: MatVideoComponent;
  video: HTMLVideoElement;

  visibleOverlayController = true;
  videosData: Observable<IVideo[]>;

  slideConfig = {
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    dots: false,
    asNavFor: '.carousel-nav',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true
        }
      }
    ]
  };
  slideNavConfig = {
    slidesToShow: 3,
    slidesToScroll: 2,
    centerPadding: '60px',
    dots: false,
    centerMode: true,
    arrows: true,
    asNavFor: '.video-holder',
    focusOnSelect: true,
    keep_infinity: false,
    nextArrow: '.arrow--right',
    prevArrow: '.arrow--left'
  };

  constructor(private videoFetcherService: VideoFetcherService) {
  }

  ngOnInit() {
    this.videosData = this.videoFetcherService.parsedData;
  }

  cancelVideo(): void {
    this.video.pause();
    this.visibleOverlayController = true;
  }

  afterVideoSwipe(event) {
    this.kopterVideoElements.forEach(video => video.resetVideo())
  }
}
