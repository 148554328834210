import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatVideoComponent} from "mat-video/lib/video.component";
import {IVideo} from "../../models/video-data.model";

@Component({
  selector: 'kopter-video-wrapper',
  templateUrl: './kopter-video-wrapper.component.html',
  styleUrls: ['./kopter-video-wrapper.component.scss']
})
export class KopterVideoWrapper implements OnInit {
  @ViewChild('matVideo', {static: true}) matVideo: MatVideoComponent;
  @Input() videoData: IVideo;
  video: HTMLVideoElement;
  visibleOverlayController = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.video = this.matVideo.getVideoTag();
      this.video.addEventListener('play', () => {
        this.visibleOverlayController = false;
      });
      this.video.addEventListener('ended', () => this.visibleOverlayController = true);
    });

  }

  startVideo(): void {
    this.video.play();
    this.visibleOverlayController = false;
  }

  resetVideo(): void {
    this.video.pause();
    this.video.currentTime = 0;
    this.visibleOverlayController = true;
  }

  private get videoFileRef(): string {
    return this.videoData.src;
  }

  private get posterFileRef(): string {
    return this.videoData.thumbnail;
  }
}
