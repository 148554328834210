// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyC-f0UAp2dQ2S43k6afZE9ezQfUIdZzyoI",
    authDomain: "kopter-api.firebaseapp.com",
    databaseURL: "https://kopter-api.firebaseio.com",
    projectId: "kopter-api",
    storageBucket: "kopter-api.appspot.com",
    messagingSenderId: "880078441731",
    appId: "1:880078441731:web:4924c106ebcb37af0d6d36",
    measurementId: "G-CYNJQXSS9H"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
