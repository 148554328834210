import {Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MailDataModel, MailSenderService} from '../mail-sender.service';

@Component({
  selector: 'app-kapcsolat',
  templateUrl: './kapcsolat.component.html',
  styleUrls: ['./kapcsolat.component.scss']
})
export class KapcsolatComponent {
  constructor(private sender: MailSenderService) {
  }

  isSending: boolean;

  submitMessage: any = {
    isSubmitted: false,
    message: '',
    isSuccess: false
  };

  data: MailDataModel = {
    name: '',
    email: '',
    subject: '',
    message: ''
  };

  onSubmit(form: NgForm) {
    this.isSending = true;
    this.sender.sendMailThroughApi(this.data)
      .then((response) => {
          this.submitMessage.message = 'Köszönjük, a levelére hamarosan válaszolunk!';
          this.submitMessage.isSuccess = true;
          this.isSending = false;
          form.resetForm();
        }
      )
      .catch((err) => {
        this.submitMessage.message = 'Sajnáljuk, de a levelet technikai okok miatt nem tudtuk elküldeni. Kérjük próbálja meg később.';
        // this.submitMessage.message = 'Sajnáljuk, de a levélküldő rendszerünk még nem működik!';
        this.submitMessage.isSuccess = false;
        this.isSending = false;
      })
      .finally(() => {
          this.submitMessage.isSubmitted = true;
        }
      );
  }

}
