import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-szolgaltatasok',
  templateUrl: './szolgaltatasok.component.html',
  styleUrls: ['./szolgaltatasok.component.scss']
})
export class SzolgaltatasokComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
