import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailSenderService {
  testBaseUrl = 'http://localhost:5000/';
  productionBaseUrl = 'https://us-central1-kopter-api.cloudfunctions.net/';

  constructor(private http: HttpClient) {
  }

  sendMailThroughApi(data: MailDataModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.productionBaseUrl + 'sendMail', {data})
        .toPromise()
        .then(
          res => resolve(res),
          msg => reject(msg)
        );
    });
  }
}

export interface MailDataModel {
  name: string;
  email: string;
  subject: string;
  message: string;
}
