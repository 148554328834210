import {NgModule} from "@angular/core";
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavigationComponent} from './navigation/navigation.component';
import {KezdolapComponent} from './kezdolap/kezdolap.component';
import {SzolgaltatasokComponent} from './szolgaltatasok/szolgaltatasok.component';
import {KapcsolatComponent} from './kapcsolat/kapcsolat.component';
import {RolamComponent} from './rolam/rolam.component';
import {FooterComponent} from './footer/footer.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {VideoCarouselSeparatorPipe} from './services/video-carousel-separator.pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SanitizerPipe } from './sanitizer.pipe';
import { BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { MatVideoModule } from 'mat-video';
import {KopterVideoWrapper} from "./kezdolap/video-wrapper/kopter-video-wrapper";
import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from '@angular/fire/storage';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    KezdolapComponent,
    SzolgaltatasokComponent,
    KapcsolatComponent,
    RolamComponent,
    FooterComponent,
    VideoCarouselSeparatorPipe,
    SanitizerPipe,
    KopterVideoWrapper
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    BrowserModule,
    MatVideoModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    SlickCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
