import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import { Observable} from "rxjs";
import {map} from "rxjs/operators";
import {IVideo, IVideoDataResponse, IVideoFirebaseDoc} from "../models/video-data.model";

@Injectable({
  providedIn: 'root'
})
export class VideoFetcherService {
  private videoCollection: AngularFirestoreCollection<IVideoFirebaseDoc>;
  videos: Observable<IVideoDataResponse>;
  parsedData: Observable<IVideo[]>;
  private source = '../../assets/videos/';

  constructor(private readonly firestore: AngularFirestore) {
    this.videoCollection = firestore.collection<IVideoFirebaseDoc>('video');
    this.videos = this.videoCollection.valueChanges();
    this.parsedData = this.videos
      .pipe(map(videoDataArray => videoDataArray.map(vd => this.convertToIMedia(vd))))
  }

  private convertToIMedia(data: IVideoFirebaseDoc): IVideo {
    return {
      title: data.title,
      src: this.videoSourceName(data),
      thumbnail: this.imageSourceName(data)
    }
  }

  private videoSourceName(videoData: IVideoFirebaseDoc): string {
    return this.source + videoData.fileName + '.' + videoData.videoType;
  }

  private imageSourceName(videoData: IVideoFirebaseDoc): string {
    return this.source + videoData.fileName + '.' + videoData.posterType;
  }
}
